// stylelint-disable declaration-no-important

// Margin and Padding
.rtl-mode {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      @each $prop, $abbrev in (margin: m, padding: p) {
        @each $size, $length in $spacers {
          .#{$abbrev}#{$infix}-#{$size} {
            #{$prop}: $length !important;
          }
          .#{$abbrev}t#{$infix}-#{$size},
          .#{$abbrev}y#{$infix}-#{$size} {
            #{$prop}-top: $length !important;
          }
          .#{$abbrev}e#{$infix}-#{$size} {
            #{$prop}-left: $length !important;
            #{$prop}-right: 0 !important;
          }
          .#{$abbrev}b#{$infix}-#{$size},
          .#{$abbrev}y#{$infix}-#{$size} {
            #{$prop}-bottom: $length !important;
          }
          .#{$abbrev}s#{$infix}-#{$size} {
            #{$prop}-right: $length !important;
            #{$prop}-left: 0 !important;
          }
        }
      }

      // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
      @each $size, $length in $spacers {
        @if $size != 0 {
          .m#{$infix}-n#{$size} {
            margin: -$length !important;
          }
          .mt#{$infix}-n#{$size},
          .my#{$infix}-n#{$size} {
            margin-top: -$length !important;
          }
          .me#{$infix}-n#{$size},
          .mx#{$infix}-n#{$size} {
            margin-right: -$length !important;
          }
          .mb#{$infix}-n#{$size},
          .my#{$infix}-n#{$size} {
            margin-bottom: -$length !important;
          }
          .ms#{$infix}-n#{$size},
          .mx#{$infix}-n#{$size} {
            margin-left: -$length !important;
          }
        }
      }

      // Some special margin utils
      .m#{$infix}-auto {
        margin: auto !important;
      }
      .mt#{$infix}-auto,
      .my#{$infix}-auto {
        margin-top: auto !important;
      }
      .me#{$infix}-auto,
      .mx#{$infix}-auto {
        margin-left: auto !important;
        margin-right: inherit !important;
      }
      .mb#{$infix}-auto,
      .my#{$infix}-auto {
        margin-bottom: auto !important;
      }
      .mx#{$infix}-auto {
        margin-right: auto !important;
        margin-left: auto !important;
      }
      .ms#{$infix}-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
      }
    }
  }
}
