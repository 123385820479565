//
// general-rtl.scss
//
.rtl-mode {
  html {
    direction: rtl;
  }

  body {
    text-align: right;
  }
}
