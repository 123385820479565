.modal-title {
  display: flex;
}
.modal-title > div:nth-child(2) {
  align-self: self-end;
  /* margin-left: 50px; */
}
.modal-title > div {
  align-self: self-end;
  margin-left: 5px;
}

.orangeBatchColor input {
  color: #ff5500;
}

/* .fixTh > thead > tr > .actionCol {
  width: 5%;
} */
table.dataMin {
  margin-bottom: 0 !important;
}

.dataMin .table-responsive {
  margin-bottom: 10px;
  /* height: 350px !important; */
}
.reportTable .table-responsive {
  height: 369px;
}
.reportTable .dataTable {
  overflow-y: initial;
  height: auto;
}
.dataTable {
  display: block;
  height: 369px;
  overflow-y: scroll;
}
.datePickerLabel {
  text-wrap: nowrap;
}
.dataMin thead {
  position: sticky; /* make the table heads sticky */
  top: 0px;
  background-color: white;
}
.reportTable thead {
  top: -1px;
}
.TableRow {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tableBorder {
  border: 1px solid black !important;
}

.tableBorder thead {
  /* background-color: #4472c4 !important; */
  color: white;
  /* width: calc(100% - 1em) */
  /* scrollbar is average 1em/16px width, remove it from thead width */
  border: 1px solid black !important;
}

.tableBorder span {
  display: flex;
  justify-content: space-between;
}

.editBtn {
  background-color: #4472c4 !important;
  border-radius: 5px;
  color: white;
}
.deleteBtn {
  background-color: red !important;
  border-radius: 5px;
  color: white;
}
.submitBtn {
  background-color: #4b515c !important;
  border-radius: 5px;
  color: white;
}

.employeeDiv {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.inputFields {
  width: 120px;
}

.employeeDropDown {
  width: 210px;
}

.employeeDiv label {
  width: fit-content;
  padding-top: 7px;
}
.datePicker {
  display: flex !important;
  align-items: center !important;
  width: 100px !important;
}
.react-datepicker__calendar-icon {
  width: 2em;
}
.recalculateBtn {
  background-color: #4472c4 !important;
  padding: 10px;
  border-radius: 10px;
  color: white;
}

.tableBtn {
  padding: 3px 15px;
  border-radius: 4px;
}

.remarkList {
  border: 1px solid #eee;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 0 0 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.remarkList i {
  font-style: normal;
}
.remarkList .ml-auto {
  margin-left: auto;
}
.remarkList button:hover {
  opacity: 0.7;
}
.text_wrap_fields {
  width: 165px;
}
.text_wrap_fields label {
  text-wrap: wrap;
}

.form-check-label {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

th div {
  white-space: nowrap;
}

.modalHead .modal-title {
  width: 100%;
}
.modalHead .modal-title h5 {
  font-size: 18px;
}
.separator {
  background: #666;
  width: 1px;
  height: 14px;
  margin: 0 2px;
  display: block;
}
button.btn-close {
  border: 1px solid #eee;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  opacity: 1;
  border-radius: 100px;
  width: 16px;
  height: 16px;
  background-size: 12px;
}

.dropable .actionCol {
  width: 4% !important;
}

@media (max-width: 500px) {
  .formColumn {
    display: block !important;
  }
  .formColumn input {
    width: 100% !important;
  }
  .flexCol {
    display: block !important;
  }
  .tableSbtBtn {
    width: 55px !important;
  }

  .tableCnlBtn {
    width: 67px !important;
  }
  .btnClass {
    display: flex !important;
    justify-content: space-between !important;
  }
  .btnClass .loadBtn {
    width: 40% !important;
  }
}
.tableCnlBtn {
  width: 40px;
}
.tableSbtBtn {
  width: 50px;
}
.flexCol {
  display: flex;
}

.formColumn {
  display: flex;
  align-items: center;
}

.disabledRow {
  /* pointer-events: none; */
  opacity: 60%;
}

.btnClass {
  display: flex;
  justify-content: center;
}
.btnClass .loadBtn {
  width: 20%;
}

.cancelLoad,
.completeLoad {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.countCheckbox.form-check-input {
  width: 50px;
  height: 24px;
}
.startDay {
  width: 180px !important;
}

.MachineFill {
  width: 100%;
}

.MachineFill .ml-auto {
  margin-left: auto;
}
.totalAmount .form-control {
  width: 160px;
}
.totalAmount {
  gap: 15px;
}
.totalAmount label {
  margin: 0;
}
.TotalCharging .formColumn {
  gap: 10px;
}
.TotalCharging .emp-col{
  width: 80%;
}
.TotalCharging .formColumn label {
  margin: 0;
  line-height: 1.25;
}
.TotalCharging .form-control {
  width: 150px;
}

.dayCheckboxes {
  column-gap: 35px;
  row-gap: 12px;
}

/* @media (max-width: 1499px) {
  .employeeLoads table.table th:nth-child(1),
  .employeeLoads table.table td:nth-child(1) {
    width: 40px !important;
  }
  .employeeLoads table.table th:nth-child(1) img,
  .employeeLoads table.table td:nth-child(1) img {
    margin: 0 auto;
    display: block;
  }
  .employeeLoads table.table th:nth-child(2),
  .employeeLoads table.table td:nth-child(2) {
    width: 90px !important;
  }
  .employeeLoads table.table th:nth-child(3),
  .employeeLoads table.table td:nth-child(3) {
    width: 200px !important;
  }
  .employeeLoads table.table th:nth-child(4),
  .employeeLoads table.table td:nth-child(4) {
    width: 200px !important;
  }
  .employeeLoads table.table th:nth-child(5),
  .employeeLoads table.table td:nth-child(5) {
    width: 180px !important;
  }
  .employeeLoads table.table th:nth-child(6),
  .employeeLoads table.table td:nth-child(6) {
    width: 80px !important;
  }
  .employeeLoads table.table.dataTable {
    overflow-y: visible;
  }
} */

@media (min-width: 1100px) {
  .checkboxes {
    max-width: calc(100% - 410px);
  }
  .checkboxes .gap-4 {
    gap: 1rem !important;
  }
  .MachineFill {
    align-items: center;
  }
}

@media (max-width: 1299px) {
  .TotalCharging .col-sm-3 {
    width: calc(50% - 10px);
  }
  .TotalCharging {
    gap: 10px;
  }
}
@media (max-width: 1199px) {
  .totalWrapper .col-sm-4 {
    width: 50%;
  }
  .totalWrapper .col-sm-4 .formColumn {
    justify-content: flex-start !important;
  }
}
@media (max-width: 1099px) {
  .ml-auto {
    width: 100%;
    margin: 0;
  }
}
@media (max-width: 767px) {
  .cancelLoad,
  .completeLoad {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .MachineFill .ml-auto .d-sm-flex {
    display: flex;
  }
  .totalWrapper {
    gap: 10px;
  }
  .totalWrapper .col-sm-4 {
    width: 100%;
    justify-content: flex-start !important;
  }
  .TotalCharging .col-sm-3 {
    width: 100%;
  }
}

@media (max-width: 499px) {
  .MachineFill .ml-auto .d-sm-flex {
    display: flex;
    flex-wrap: wrap;
  }
  .text_wrap_fields {
    width: 100%;
  }
}

.errorMSG {
  position: absolute;
  bottom: -4px;
  line-height: 1;
  left: 10px;
}

.errorSearchBox {
  position: absolute;
  bottom: -15px;
  line-height: 1;
  left: 10px;
  color: red;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
